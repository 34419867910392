import React from 'react'
import './Contactus.css'

function ContactUs() {
    return (
        <div className='contactus_wrapper'>
            <div className='contactus_text_area'>
                <div className='heading_contactus'>
                    CONTACT US
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Director
                    </div>
                    <div className='large_text'>
                    
                    Karabo Handerson
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Phone Number
                    </div>
                    <div className='large_text'>
                    081 449 0945
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Email Address
                    </div>
                    <div className='large_text'>
                    KMNHSupplies@gmail.com
                    </div>
                </div>

                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Physical Address
                    </div>
                    <div className='large_text'>
                    1 Constantia Avenue, Pomona, Kempton Park
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ContactUs